<script setup lang="ts">
import Cookies from 'js-cookie';
import { agreeToAll, markConsentAndDate, setDefaultConsents } from '~/lib/gdpr-cookies';

const localePath = useLocalePath();
const router = useRouter();
const { addMonths, format, isAfter } = useDateFns();
const generalStore = useGeneralStore();

const loaded = ref(false);
const hasSeenCookies = ref(!!Cookies.get('cookies_consent_given'));
const hasAgreedToCookies = ref(!!Cookies.get('cookies_consent'));
const dateOfConsent = ref<string | undefined>(Cookies.get('cookies_consent_given'));
const showCookiesBanner = ref(false);

generalStore.$onAction(({ name }) => {
  if (name === 'reshowCookiesBanner') {
    hasSeenCookies.value = false;
  }
});

const hideBanner = () => {
  hasSeenCookies.value = true;
};
const agree = () => {
  hasSeenCookies.value = true;
  showCookiesBanner.value = false;

  markConsentAndDate(format(new Date(), 'yyyy-MM-dd'));
  agreeToAll();
};
const remindCookies = () => {
  if (hasSeenCookies.value && !hasAgreedToCookies.value) {
    const today = new Date();
    const dateOfConsentAfterMonth = addMonths(
      dateOfConsent.value ? new Date(dateOfConsent.value) : new Date(),
      1,
    );

    // today is one month after user rejected cookies
    if (isAfter(today, dateOfConsentAfterMonth)) {
      hasSeenCookies.value = false;
    }
  }

  window.setTimeout(() => {
    if (!hasSeenCookies.value) {
      showCookiesBanner.value = true;
    }
  }, 3_000);
};
const setGtmConsents = () => {
  if (!hasSeenCookies.value) {
    const interval = window.setInterval(() => {
      if (!window.dataLayer) {
        return;
      }

      setDefaultConsents();

      window.clearInterval(interval);
    }, 1_000);
  }
};

onMounted(async () => {
  setGtmConsents();

  await router.isReady();

  if (router.currentRoute.value.path === localePath('privacy-settings')) {
    hasSeenCookies.value = true;
  } else {
    remindCookies();
  }

  loaded.value = true;
});
</script>

<template>
  <div v-show="showCookiesBanner" id="cookiescontent">
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col col--image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 133 154.03"
              width="133"
              height="154.03"
            >
              <path fill="#dfa839" d="m133 84.94-66.45 38.37L0 84.94l66.55-38.45Z" />
              <path fill="#cc8640" d="M66.55 123.31V154L0 115.58V84.94Z" />
              <path fill="#bc743f" d="M66.55 123.31V154L133 115.58V84.94Z" />
              <path
                fill="#630"
                d="M74.21 83.2 65.83 88l-8.37-4.8 8.37-4.79ZM56.66 62.46l-8.79 4.78-8.77-4.78 8.77-4.8Zm34.34-2-8.78 5.18-8.78-5.18 8.78-5.19ZM32.19 93.58l8.66 5-8.66 5v10l-8.65-5v-10Z"
              />
              <path fill="#311900" d="m32.35 113.6-8.65-5v-10l8.65 5Z" />
              <path fill="#630" d="m104.14 78.41 8.38 4.78-8.38 4.81-8.38-4.79Z" />
              <path fill="#4f2800" d="m87 101.37 8.77 5v10l-8.77 5v-10l-8.78-5Z" />
              <path fill="#630" d="M95.72 106.55 87 111.74l-8.78-5.19 8.78-5.18Z" />
              <path fill="#dfa839" d="M133 38.48 66.55 76.85.05 38.45 66.59 0Z" />
              <path fill="#cc8640" d="M66.55 76.85v30.72L0 69.12V38.48Z" />
              <path fill="#bc743f" d="M66.55 76.85v30.72L133 69.12V38.48Z" />
              <path
                fill="#630"
                d="m80.21 35.75-8.38 4.77-8.37-4.77L71.83 31ZM37.1 43.54l-8.78 5.17-8.78-5.17 8.78-5.2Zm17.56-26.62-8.74 4.86-8.82-4.7L45.94 12ZM91 13.89l-8.74 5.29-8.84-5.07L81.93 9ZM53.19 59.12l8.66 5-8.66 5v10l-8.65-5v-10Z"
              />
              <path fill="#311900" d="m53.35 79.14-8.65-5v-10l8.65 5Z" />
              <path fill="#630" d="m81.14 51 8.38 4.78-8.38 4.79-8.38-4.79Z" />
              <path fill="#4f2800" d="m120 35.91 8.77 5v10l-8.77 5v-10l-8.78-5Z" />
              <path fill="#630" d="M128.72 41.09 120 46.28 111 41l9-5.08Z" />
            </svg>
          </div>

          <div class="col">
            <h4>{{ $t('layout.cookies.title') }}</h4>

            <i18n-t tag="p" keypath="layout.cookies.text" scope="global">
              <template #link>
                <NuxtLink :to="localePath('privacy-settings')" @click="hideBanner">{{
                  $t('layout.links.privacy-settings')
                }}</NuxtLink>
              </template>
            </i18n-t>

            <div class="button-group">
              <Button
                as="NuxtLink"
                variant="text"
                theme="light"
                :to="localePath('privacy-settings')"
                @click="hideBanner"
              >
                {{ $t('layout.cookies.actions.go-to-settings') }}
              </Button>

              <Button @click="agree">{{ $t('layout.cookies.actions.agree') }}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#cookiescontent {
  position: fixed;
  bottom: 0;
  z-index: 20001;

  width: 100%;

  background-color: var(--white);
  color: var(--black);
}

.wrapper {
  background: linear-gradient(290.24deg, #e5e5e5 26.87%, rgba(245, 245, 245, 0) 72.99%);
  box-shadow: 0px 0px 1.25rem rgba(0, 0, 0, 0.1);
}

.row {
  display: grid;
  grid-template-columns: 1fr;
  padding-block: 2.5rem;

  @media (min-width: 576px) {
    grid-template-columns: 12rem 1fr;
  }
}

.col--image {
  display: none;

  @media (min-width: 576px) {
    display: flex;
    justify-content: center;

    padding-top: 2rem;
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;

  a {
    margin-right: 0.5rem;
  }
}

a:visited {
  color: var(--color, var(--black));
}
</style>
